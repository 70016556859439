<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <h3 class="mb-1">
          Login to Start Playing
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card class="mb-1">
          <!-- thank you message -->
          <b-alert
            variant="success"
            :show="isNewRegistration"
          >
            <div class="alert-body">
              Thank you for registering! Please log in to start playing.
            </div>
          </b-alert>

          <validation-observer
            ref="loginForm"
            #default="{ invalid, dirty }"
          >
            <b-form
              class="form-container"
              @submit.prevent="login"
            >
              <b-row class="mt-1">
                <b-col md="12">
                  <b-form-group
                    class="text-primary"
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="email"
                        v-model.trim="userEmail"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="text-primary"
                    label="Password"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                      vid="password"
                    >
                      <b-form-input
                        id="password"
                        v-model.trim="password"
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        trim
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  class="form-group"
                >
                  <b-link to="/recover-password">
                    Forgot your password?
                  </b-link>
                </b-col>
                <b-col
                  cols="6"
                  class="form-group text-right"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="invalid || !dirty"
                  >
                    Login
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BContainer, BRow, BCol,
  BAlert, BButton, BCard,
  BForm, BFormGroup, BFormInput,
  BLink,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import IndexBackground from '@/assets/images/backgrounds/auth.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BAlert,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BLink,

    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userEmail: '',
      password: '',
      required,
      email,
    }
  },
  computed: {
    isNewRegistration() {
      return this.$route.name === 'thank-you'
    },
  },
  created() {
    document.body.style.backgroundImage = `url(${IndexBackground})`
    document.body.style.backgroundSize = 'cover'
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log('login', response.data.data)
              const loginData = response.data.data
              const loginToken = loginData.token

              useJwt.setToken(loginToken)

              useJwt.getCurrentUser()
                .then(userResponse => {
                  const userData = userResponse.data.data

                  userData.fullName = `${userData.first_name} ${userData.last_name}`
                  console.log('userData', userData) // DELETE LATER

                  localStorage.setItem('userData', JSON.stringify(userData)) // needs to be changed later

                  // broadcast event that user is logged in
                  this.$root.$emit('userLoggedIn')

                  this.$router.push('/home')
                })
                .catch(error => {
                  console.log('error', error.response)
                  if (error.response.status === 403) { // forbidden
                    // this.$router.push({ name: 'error-404' })
                  } else if (error.response.status === 404) { // not found
                    // this.$router.push({ name: 'error-404' })
                  }
                })
            })
            .catch(error => {
              // highlight Email field with the received error
              if (error.response.data.error === 'Unauthorized') {
                this.$refs.loginForm.setErrors({ password: 'Incorrect email/password' })
              } else {
                this.$refs.loginForm.setErrors({ password: error.response.data.details || error.response.data.error })
              }
            })
        }
      })
    },
  },
}
</script>

<style>
</style>
