var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"mb-1"},[_vm._v(" Login to Start Playing ")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-1"},[_c('b-alert',{attrs:{"variant":"success","show":_vm.isNewRegistration}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Thank you for registering! Please log in to start playing. ")])]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"text-primary",attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"email","type":"text","placeholder":"Email","trim":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"text-primary",attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"password","type":"password","placeholder":"Password","trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"form-group",attrs:{"cols":"6"}},[_c('b-link',{attrs:{"to":"/recover-password"}},[_vm._v(" Forgot your password? ")])],1),_c('b-col',{staticClass:"form-group text-right",attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" Login ")])],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }